@import "../../styles/variables";
@import "../../styles/mixins";

.settings-toolbar {
  z-index: 5;
  display: grid;
  grid-template-columns: 250px 1fr 250px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-bottom: 24px;
  column-gap: 48px;

  .toggle-buttons {
    @include margin-auto
  }

  .toggle-buttons--paw {
    margin-right: 0;
  }

  .search-box input {
    width: 100%;
  }
}

.search-box {
  position: relative;
  display: flex;

  input {
    padding: 6px 14px 6px 130px;
    border-radius: 2em;
    color: white;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: .05em;
    text-align: center;
    box-sizing: border-box;
    background-color: lighten($color-body-dark, 5%);
    border: none;
    transition: background-color .3s ease-out;
    font-family: $font-roboto;

    &:focus-visible {
      outline: none;
      color: black;
      background-color: lighten($card-color-dark, 70%);
    };
  }

  &:before {
    z-index: 1;
    content: "Search chords:";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    padding: 0 12px;
    color: black;
    height: 30px;
    background-color: lighten($card-color-dark, 70%);
    border-radius: 2em;
    white-space: nowrap;
    @include prevent-click;
    @include prevent-select;
  }
}
@import "./variables";
@import "./mixins";
@import "https://meyerweb.com/eric/tools/css/reset/reset.css";

:root {
  --accent-color-1: #{map-get($color-accent, 1)};
  --accent-color-2: #{map-get($color-accent, 2)};
  --accent-color-3: #{map-get($color-accent, 3)};
  --accent-color-4: #{map-get($color-accent, 4)};
  --accent-color-5: #{map-get($color-accent, 5)};
  --accent-color-6: #{map-get($color-accent, 6)};
  --accent-color-7: #{map-get($color-accent, 7)};
  --primary-color: #{$color-primary};
  --color-body-dark: #{$color-body-dark};
}

body {
  color: #333;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
}

.body {
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: var(--color-body-dark);
    padding-top: 70px;
    font-family: Roboto;
    font-weight: 400;
    position: relative;
}

h1 {
  color: #5e5e5e;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 80px;
  font-weight: 400;
  line-height: 58px;
}

h2 {
  line-height: 36px;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color .3s cubic-bezier(.215, .61, .355, 1);
}

a:hover {
  color: var(--primary-color);
}

img {
  max-width: 100%;
  display: inline-block;
}

blockquote {
  white-space: nowrap;
  border-left-style: none;
  padding: 10px 0;
}

figure {
    margin: 0 0 10px;
}

.image {
  z-index: 1;
  @include size(100%, 110%);
  object-fit: cover;
  position: relative;
}

.border-radius {
  border-radius: $border-radius-xs;
  overflow: hidden;
}

.main-logo {
  width: 130px;
}

.content-wrapper {
  z-index: 8;
  width: 100%;
  display: block;
  position: relative;
  //overflow: hidden;
}

.content-wrapper.content-wrapper--centered {
  max-width: 1278px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @include margin-auto;
  display: flex;
}

.container {
  width: calc(100% - 24px);
  display: block;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  @include margin-auto;
  text-align: center;

  @include above($tablet) {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }

  @include above($desktop) {
    width: calc(100% - 128px);
    max-width: 1280px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

.notes-grid__note-name {
  text-align: center;
  -webkit-text-fill-color: inherit;
  cursor: default;
  background-clip: border-box;
  justify-content: center;
  align-items: center;
  font-family: Noto Serif Display, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 40px;
  position: absolute;
  transform: translate(12px, -58px);
}

.text-span-12 {
  color: $color-note-default;
  letter-spacing: .03em;
  font-family: Times New Roman, TimesNewRoman, Times, Baskerville, Georgia, serif;
  font-size: 19px;
  font-style: italic;
  position: relative;
  top: -18.5px;
}

.section__overflow {
  position: relative;
  padding-bottom: 0;
  width: 100%;
  
  @include below($desktop - 1) {
    overflow: hidden;
  }

  @include below($tablet) {
      margin: 0 -12px;
      width: calc(100% + 24px);
  }
}

.content-section {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:not(:first-child) {
    padding: 48px 0;
  }

  &.content-section--row {

    @include above($desktop) {
      display: flex;
      column-gap: 48px;
      align-items: center;
  
      & > div {
        flex: 1;
      }
    }
  }
}
  
.text-italic {
  font-style: italic;
}

span.diez-symbol {
  font-family: $font-noto-serif;
  font-size: 0.8em;
}
@import "../../styles/variables";
@import "../../styles/mixins";

.section.section--fh {
    height: 100vh;
    min-height: 950px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -70px;
    display: flex;
  }
  
  .hero-headline-wrapper {
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
    display: flex;
  }
  
  .hero-headline {
    color: var(--primary-color);
    letter-spacing: -.02em;
    margin-bottom: 40px;
    margin-left: -2px;
    font-family: $font-noto-serif;
    font-size: 72px;
    line-height: 64px;
    font-weight: 400;
    display: block;

    @include above($tablet) {
      font-size: 140px;
      line-height: 122px;
    }

    @include above($desktop) {
      font-size: 170px;
      line-height: 142px;
    }
  }
  
  .hero-headline__italic {
    letter-spacing: .04em;
    margin-bottom: -80px;
    font-family: $font-garamond;
    font-style: italic;
    font-weight: 100;
    font-size: .7em;
    display: inline-block;
  }
  
  .subtitle-text {
    color: $color-note-default;
    letter-spacing: -.02em;
    font-family: $font-garamond;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    @include above($tablet) {
      font-size: 30px;
      line-height: 32px;
    }

    @include above($desktop) {
      font-size: 40px;
      line-height: 48px;
    }
  }

.hero-image {
    z-index: -4;
    width: 100vw;
    height: 100vh;
    opacity: .22;
    object-fit: cover;
    background-image: linear-gradient(to right, #000, #fff);
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%);
}
@mixin below ($breakpoint) {
    @media only screen and (max-width: ($breakpoint - 1)) {
      @content;
    }
}

@mixin above ($breakpoint) {
    @media only screen and (min-width: $breakpoint) {
      @content;
    }
}

@mixin between ($breakpoint-min, $breakpoint-max) {
    @media only screen and (min-width: $breakpoint-min) and (max-width: $breakpoint-max - 1) {
      @content;
    }
}

@mixin size ($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin position ($top: false, $left: false, $right: false, $bottom: false) {
  @if $top {
    top: $top
  }
  @if $left {
    left: $left
  }
  @if $right {
    right: $right
  }
  @if $bottom {
    bottom: $bottom
  }
}

@mixin margin-auto {
  margin-left: auto;
  margin-right: auto;
}

@mixin prevent-select {
  user-select: none;
}

@mixin prevent-click {
  pointer-events: none;
  cursor: default;
}
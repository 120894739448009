$color-primary: #9b8849;
$card-color-dark: #131313fa;
$color-body-dark: #0b0b0b;

$color-note-default: #898a91;
$color-note-default-dark: #393a3f;

$color-accent: (
    1: #5fb6cc,
    2: #878ce6,
    3: #9b55f1,
    4: #cf5ccc,
    5: #e55f7c,
    6: #cea760,
    7: #9fb94f
);

$font-garamond: Cormorant Garamond, Georgia, Times New Roman, serif;
$font-roboto: PT Sans, Roboto, Arial, sans-serif;
$font-noto-music: Noto Music, sans-serif;
$font-noto-serif: Noto Serif Display, sans-serif;

$border-radius-xs: 5px;

$mobile: 480px;
$tablet: 768px;
$desktop: 990px;
$desktop-l: 1280px;
$desktop-xl: 1440px;
$desktop-xxl: 1920px;
@import "../../styles/variables";
@import "../../styles/mixins";

.chord-sound-wrapper {
    z-index: 1;
    @include size(48px);
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  
    @include below($desktop){
      bottom: 24px;
    }
  
    @include below($tablet){
      @include position (false, 8px, false, 16px);
    }
  }
  
  .chord-sound {
    @include size(100%);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-family: $font-noto-music;
    color: white;
    font-size: 24px;
    line-height: 24px;
    background-color: rgba(white, 5%);
    transition: background-color .4s ease-out;
    display: flex;
  
    &:hover {
      color: #fff;
      background-color: var(--primary-color);
    }
  
    svg {
      @include size(100%);
  
      use {
        transform: scale(.4);
        transform-origin: 53% 50%;
      }
    }
  }
  
  .material-icon--sound {
    @include size(48px);
    color: rgba(255, 255, 255, .6);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-family: $font-noto-music;
    font-size: 24px;
    line-height: 48px;
    transition: color .3s, opacity .3s, transform .3s, box-shadow .3s ease-out;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  
  .sound-widget {
    z-index: 1;
    position: absolute;
    @include position(false, 20px, false, 20px);

    &.sound-widget--shaded {
        position: relative;
        display: inline-flex;
        align-items: center;
        top: 0;
        left: 0;
        background-color: rgba(white, 5%);
        border-radius: 3em;
        margin: 0 auto;

        .chord-sound-wrapper {
            @include size(64px)
        }

        .sound-wave-icon {
            position: relative;
            left: 0;
            margin-left: 10px;
            margin-right: 20px;
        }
    }
  }
  
  .sound-wave-icon {
    position: absolute;
    @include position(false, calc(100% + 4px), false, 0);
    @include size(80px, 48px);
    opacity: 0.5;
    @include prevent-select;
    @include prevent-click;
  }


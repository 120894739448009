@import "../../styles/variables";
@import "../../styles/mixins";


.notes-grid {
    padding: 48px 0;
}


.tabs__row {
    position: relative;
    width: 100%;
    display: flex;

    &[comment] {
        &:after {
            z-index: 2;
            content: attr(comment);
            position: absolute;
            padding: 1px 7px;
            background: none;
            top: 2px;
            left: -16px;
            transform: translateY(-50%);
            font-family: $font-roboto;
            font-weight: normal;
            white-space: nowrap;
            font-size: 18px;
            text-align: center;
            color: $color-body-dark;
            background-color: rgba(white, 70%);
            backdrop-filter: blur(4px);
            border-radius: 2em 2em 0 2em;
        }
    }

    &:last-child {
        .note-grid__brick--sixteenth {
            @include below($tablet) {
                height: 50px !important;
            }
        }
    }
}

.notes-grid__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    //gap: 2px;

    @include below($tablet) {
        margin-left: -12px;
        width: calc(100% + 24px);
    }
}

.note-grid__brick {
    position: relative;
    display: flex;
    height: 100px;
    color: #0b0b0b;
    text-align: center;
    background-image: linear-gradient(rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
    flex: 1;
    justify-content: center;
    align-items: center;
    font-family: Noto Music, sans-serif;
    font-size: 40px;
    color: white;
    line-height: 2em;
    overflow: hidden;

    @include below($tablet) {
        height: 70px;

        svg {
            transform: scale(.75) !important;
        }
    }

    .note__character {
        align-items: center;
    }

    svg {
        color: white;
        transform: translateX(0px);
    }

    &:after {
        content: "";
        z-index: -1;
        position: absolute;
        @include position(1px, 1px);
        @include size(calc(100% - 2px));
        border-radius: 3px;

        @include below($tablet) {
            @include position(0, 0);
            @include size(calc(100% - 1px));
        }
    }

    .note {
        transform: none !important;
        padding-top: 0 !important;

        &::before {
            content: none !important;
        }
    }

    &.note-grid__brick--full {
        flex: 16;

        &:after {
            background-color: var(--accent-color-1);
        }

        .note:not(.note--pause) {
            svg {
                @include size(48px !important, 76px !important);
                transform: translate(10px, -30px) !important;
            }
        }

        &.note-grid__brick--extended-1 {
            flex: 24;
        }

        &.note-grid__brick--extended-2 {
            flex: 28;
        }
    }

    &.note-grid__brick--half {
        flex: 8;
        
        &:after {
            background-color: var(--accent-color-2);
        }

        svg {
            @include size(36px, 60px);
        }

        &.note-grid__brick--extended-1 {
            flex: 12;
        }

        &.note-grid__brick--extended-2 {
            flex: 14;
        }
    }

    &.note-grid__brick--quart {
        flex: 4;
        
        &:after {
            background-color: var(--accent-color-3);
        }

        svg {
            @include size(32px, 55px);
        }

        &.note-grid__brick--extended-1 {
            flex: 6;
        }

        &.note-grid__brick--extended-2 {
            flex: 7;
        }
    }

    &.note-grid__brick--eight {
        flex: 2;
        
        &:after {
            background-color: var(--accent-color-4);
        }

        svg {
            @include size(28px, 44px);
        }

        &.note-grid__brick--extended-1 {
            flex: 3;
        }

        &.note-grid__brick--extended-2 {
            flex: 3.5;
        }
    }

    &.note-grid__brick--sixteenth {
        flex: 1;
        
        &:after {
            background-color: var(--accent-color-5);
        }

        svg {
            @include size(25px, 40px);
        }

        &.note-grid__brick--extended-1 {
            flex: 1.5;
        }

        &.note-grid__brick--extended-2 {
            flex: 1.75;
        }
    }

    &.note-grid__brick--greyscale {
        background-image: none;

        svg {
            color: rgba(white, 10%);
        }

        &:after {
            background-color: rgba(white, 5%) !important;
        }
    }
}
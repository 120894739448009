@import "../../styles/variables";
@import "../../styles/mixins";

.tabs-grid__stave {
    @include size(calc(100% - 60px), 101px);
    padding-left: 60px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="20" viewBox="0 0 100 20" fill="none"><rect y="19" width="100" height="1" fill="white" fill-opacity="0.16"/></svg>');
    background-position: 0 -19px;
    background-size: auto;
    grid-template-rows: 121px;
    grid-template-columns: minmax(40px, 1fr) minmax(40px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;
    position: relative;

    &::before {
        content: "TAB";
        position: absolute;
        @include position(4px, 0);
        @include size(32px, 100%);
        overflow-wrap: anywhere;
        font-family: Minervamodern;
        font-size: 37px;
        font-style: normal;
        line-height: 31px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        background-clip: text;
        background-color: $color-note-default;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.23)), linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36));
    }
}

.tabs-grid_nums {
    width: 60px;
    text-align: center;
    align-items: center;
    display: flex;
    position: relative;
}

.tabs-grid__tabs-nums {
    @include position(-9.5px, false, -32px);
    @include size(32px, 96px);
    opacity: .5;
    color: #5e5e5e;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    position: absolute;
}

.tabs-grid__num-char {
    text-align: right;
    font-family: $font-roboto;
    line-height: 20px;
}

.tabs-grid__tabs-bar {
    display: flex;
    flex: 1;
    padding: 0 24px;
    border-right: 3px solid $color-note-default-dark;
}

.tabs-grid__note {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    height: 20px;
    min-width: 50px;
    color: #0b0b0b;
    text-align: center;
    cursor: default;
    font-family: $font-roboto;
    font-size: 18px;
    font-weight: 700;
    @include prevent-select;

    span {
        @include size(20px);
        line-height: 20px;
        @include position(50%, 50%);
        background-color: rgba(255, 255, 255, .95);
        transition: transform .3s cubic-bezier(.165, .84, .44, 1);
        border-radius: 20px;
    }

    &.tabs-grid__note--1 {
        top: -10px;
    }

    &.tabs-grid__note--2 {
        top: 10px;
    }

    &.tabs-grid__note--3 {
        top: 30px;
    }

    &.tabs-grid__note--4 {
        top: 52px;
    }

    &.tabs-grid__note--5 {
        top: 72px;
    }

    &.tabs-grid__note--6 {
        top: 92px;
    }

    &.tabs-grid__note--full {
        flex: 16;

        &-x1 {
            flex: 24;
        }

        &-x2 {
            flex: 32;
        }
    }

    &.tabs-grid__note--half {
        flex: 8;

        &-x1 {
            flex: 12;
        }

        &-x2 {
            flex: 16;
        }
    }

    &.tabs-grid__note--quart {
        flex: 4;

        &-x1 {
            flex: 6;
        }

        &-x2 {
            flex: 8;
        }
    }

    &.tabs-grid__note--eight {
        flex: 2;

        &-x1 {
            flex: 3;
        }

        &-x2 {
            flex: 4;
        }
    }

    &.tabs-grid__note--sixteenth {
        flex: 1;

        &-x1 {
            flex: 1.5;
        }

        &-x2 {
            flex: 2;
        }
    }
}
@import "../../styles/variables";
@import "../../styles/mixins";

.chord-wrapper {
  padding: 5px;
  position: relative;

  &.chord-fw-wrapper {
    @include size(100vw, 640px);
    margin-top: -200px;
    margin-bottom: -200px;
    padding-top: 145px;
    top: -60px;
    right: 0;
    overflow: hidden;

    .chord {
      height: 505px
    }

    // @include above($desktop-xl) {
    //   &:after {
    //     background-position: 0 0, 70%;
    //   }
    // }
  }
}

.chord {
  @include size(100%, 350px);
  background-color: $card-color-dark;
  border-bottom-right-radius: $border-radius-xs;
  border-bottom-left-radius: $border-radius-xs;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;

  &.section-fw-chord {
    background-color: rgba(0, 0, 0, 0);
    overflow: visible;

    .chord__visual {
      background-image: none;
      background-repeat: repeat;
      background-size: auto;
  
      @include above($desktop-xl + 54) {
        right: auto;
        left: 219px;
      }
    
      &:after {
        content: "";
        position: relative;
        z-index: -1;
        @include size(200vw, 640px);
        background-image: linear-gradient(#0b0b0b, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%, #0b0b0b), url("https://uploads-ssl.webflow.com/629e5388ef3d3bca1315d8de/643464716871bbb0542130a5_g-fw.webp");
        background-position: 0 0, 100%;
        background-repeat: repeat, no-repeat;
        background-size: auto, auto 100%;
        display: block;
        position: absolute;
        top: 50%;
        bottom: auto;
        left: auto;
        right: -536px;
        transform: translate(50px, -50%);
      }
    }
  }
}

.chord-toolbar {
  @include size(100%, 80px);
  background-color: $card-color-dark;
  border-top-left-radius: $border-radius-xs;
  border-top-right-radius: $border-radius-xs;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;

  &.chord-toolbar--absolute {
    z-index: 3;
    height: 48px;
    position: relative;
  }
}

.chord-toolbar__title-wrapper {
  z-index: 4;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.chord-toolbar__picture {
  z-index: 4;
  @include size(100px, 100%);
  min-height: 80px;
  transform-origin: 100% 0;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0 0 5px #0b0b0b;

  @include below($mobile){
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    @include size(100%);
    object-fit: cover;
  }
}

.chord-toolbar__subtitle {
  z-index: 4;
  color: rgba(137, 138, 145, .56);
  cursor: pointer;
  margin-top: 12px;
  margin-right: 4px;
  font-family: PT Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.chord__finger-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  @include size(20px);
  color: #0b0b0b;
  text-align: center;
  cursor: default;
  background-color: rgba(255, 255, 255, .95);
  border-radius: 20px;
  font-family: $font-roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  transition: transform .3s cubic-bezier(.165, .84, .44, 1);
  position: absolute;
  transform: translate(0, -1px);
  @include prevent-select;

  &:hover {
    box-shadow: none;
    color: #0b0b0b;
    background-color: rgba(255, 255, 255, .95);
    box-shadow: 0 0 3px 3px rgba(17, 16, 16, .21);
    transform: scale(1.4);
  }

  &.chord__finger-circle--0 {
    box-shadow: none;
    opacity: .6;
    background-color: rgba(255, 255, 255, 0);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" style="fill: %23fff;" xml:space="preserve"><path d="M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z M12,18c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S15.3,18,12,18z"/></svg>');
    background-position: 50%;
    background-size: 16px 16px;
    transform: scale(1.2) translateX(-2px);
  }

  &.chord__finger-circle---1 {
    box-shadow: none;
    opacity: .94;
    background-color: rgba(255, 255, 255, 0);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><style type="text/css">.st0{fill:none;}.st1{fill:%23EA65B4;}</style><path class="st0" d="M0,0h24v24H0V0z"/><path class="st1" d="M18.3,5.7c-0.4-0.4-1-0.4-1.4,0L12,10.6L7.1,5.7c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.9,4.9l-4.9,4.9 c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l4.9-4.9l4.9,4.9c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L13.4,12l4.9-4.9C18.7,6.7,18.7,6.1,18.3,5.7z"/></svg>');
    background-position: 50%;
    background-size: 16px 16px;
    transform: scale(1.4) translateX(-1.5px);
  }

  &.chord__finger-circle--barre {
    justify-content: center;
    align-items: center;
    display: flex;
    top: -8px;

    &:hover {
      transform: scale(1.15);
    }
  }
}

.chord__title {
  z-index: 4;
  color: var(--primary-color);
  text-align: center;
  padding-left: 32px;
  padding-right: 32px;
  font-family: Noto Serif Display, sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 60px;
  text-decoration: none;
  transition: color .3s cubic-bezier(.215, .61, .355, 1);
  position: relative;
  &:hover {
    color: var(--primary-color);
  }
}

.chord-versions {
  z-index: 4;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: rgba(255, 255, 255, .25);
  cursor: default;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;
  padding-top: 8px;
  font-family: $font-roboto;
  font-style: italic;
  font-weight: 300;
  display: flex;
  position: absolute;
  bottom: 28px;
  right: 24px;
}

.chord__strings {
  z-index: 2;
  @include size(100%);
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  position: absolute;
  @include position (0, 0);
}

.chord__string {
  position: absolute;
  @include size(100%, 1px);
  transform-origin: 0;

  $strings-angles: -.7deg, -.4deg, -.15deg, .15deg, .4deg, .7deg;

  @each $string-angle in $strings-angles {
    $i: index($strings-angles, $string-angle);
    &.chord__string--#{$i} {
      transform: rotate(#{$string-angle});
      bottom: #{(10 - ($i * 21.5)) * -1}px;
    }
  }
}
$positions: 40px, 118px, 196px, 266px, 333px, 397px, 457px, 512px, 565px, 615px, 663px, 708px, 750px, 790px, 828px, 863.5px, 898px, 929px, 959px, 987px, 1014.5px;

.chord__position {
  z-index: 3;
  @include size(32px);
  color: rgba(255, 255, 255, .42);
  text-align: center;
  cursor: default;
  font-family: $font-garamond;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  @include position (-35px,false,0);
  transform: translate(50%);

  &.chord__position--1 {
    display: none;
  }
  @each $position in $positions {
    $i: index($positions, $position);
    &.chord__position--#{$i} {
      margin-right: $position;
    }
  }
}

.chord__visual {
  @include size($desktop-l - 2, 350px);
  background-image: linear-gradient(#131313, rgba(19, 19, 19, 0) 18%), url("https://uploads-ssl.webflow.com/629e5388ef3d3bca1315d8de/643464d69aff21cb41fef40c_g-sw.webp");
  background-position: 0 0, 100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, 100%;
  position: absolute;
  right: 0;
}

.chord__neck {
  @include size(1059px, 130px);
  position: absolute;
  @include position (110px, 62px);
}

.chord__finger {
  @include size(1px);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  transform: translate(0, -50%);



  &[barre="true"] {
    .chord__finger-circle:hover {
      transform: scale(1.15);
    }
  }

  &.chord__fret--0 {
    position: sticky;
    @include position (false, 100%, 0);
    transform: translate(-6px, -50%);
  }

  &.chord__fret--0-1 {
    position: sticky;
    @include position (false, 100%, 0);
    transform: translate(11px, -50%);
  }

  &.chord__fret---1 {
    position: sticky;
    @include position (false, 100%, 0);
    transform: translate(-6px, -50%);
  }

  @each $position in $positions {
    $i: index($positions, $position);
    &.chord__fret--#{$i} {
      right: $position;
    }
  }
}

.chord-versions__link {
  font-family: $font-garamond;
}

.hand-popover {
  z-index: 5;
  @include size(200px);
  color: rgba(17, 16, 16, .9);
  background-color: #fff;
  background-image: url("https://uploads-ssl.webflow.com/629e5388ef3d3bca1315d8de/62bb5db4bf49296f39524933_hand.webp");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  font-family: $font-roboto;
  justify-content: center;
  align-items: flex-end;
  display: none;
  position: absolute;
  @include position (0, 0, auto, auto);
  box-shadow: 1px 1px 26px -6px rgba(17, 16, 16, .9);
}

.hand-popover__finger {
  @include size(18px);
  opacity: .22;
  text-align: center;
  border: 2px solid #000;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
  display: flex;
  position: absolute;
  top: 0;
}

.hand-popover__finger.hand-popover__finger--4 {
  @include position (36px, 140px);
}

.hand-popover__finger.hand-popover__finger--3 {
  @include position (13px, 114px);
}

.hand-popover__finger.hand-popover__finger--2 {
  @include position (6px, 85px);
}

.hand-popover__finger.hand-popover__finger--1 {
  @include position (16px, 54px);
}

.hand-popover__finger.hand-popover__finger--t {
  @include position (89px, 10px);
}

.hand-popover__finger.hand-popover__finger--active {
  opacity: 1;
}

body.lefty {
  .chord {
    transform: rotateX(0)rotateY(180deg)rotateZ(0);
  }

  .chord__finger-circle,
  .hand-popover,
  .chord-toolbar__picture img {
    transform: scaleX(-1)!important;
  }
}
@media print {
    .header, .chord-toolbar__picture, .chord-versions {
        display: none;
    }

    .chord__string {
      background-color: black !important;
      height: 3px;
    }

    .chord__position {
      color: black !important;
    }

    .chord {
      border: 1px solid black
    }
}

@import "../../styles/variables";
@import "../../styles/mixins";

.note-scheme {
    width: 600px;
    max-width: 100%;
    @include prevent-select;
    @include prevent-click;

    line {
        opacity: .3;
    }
    
    .note-scheme__steam {
        fill: var(--accent-color-3);
        stroke: var(--accent-color-3);
    }

    .note-scheme__flag {
        fill: var(--accent-color-2);
        stroke: var(--accent-color-2);
    }

    .note-scheme__head {
        fill: var(--accent-color-1);
        stroke: var(--accent-color-1);
    }

    text {
        font-family: $font-garamond;
        font-weight: normal;
        font-size: 28px;
    }
}

#Gradient1 {
    .stop1 {
        stop-color: var(--accent-color-3)
    }

    .stop2 {
        stop-color: var(--accent-color-3)
    }

    .stop3 {
        stop-color: var(--accent-color-1)
    }
}

#Gradient2 {
    .stop1 {
        stop-color: var(--accent-color-3)
    }

    .stop2 {
        stop-color: var(--accent-color-2)
    }
}
@import "../../../styles/variables";
@import "../../../styles/mixins";

.toggle-buttons {
  display: flex;
  column-gap: 4px;

  .toggle-buttons__item {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 30px;
    overflow: hidden;
    border-radius: 2em;
    cursor: pointer;
    @include prevent-select;
    transition: background-color .15s ease-out, color .15s ease-out;
    white-space: nowrap;
    font-family: $font-roboto;

    &:not(.is-active) {
      color: darken(white, 60%);

      &:hover {
        background-color: lighten($card-color-dark, 4%);
        color: var(--primary-color);

        .toggle-buttons__icon {
          transform: translateY(2px);
        }
      }
    }

    &.is-active {
      color: black;
      background-color: lighten($card-color-dark, 70%);
      @include prevent-click;
    }
  }

  .toggle-buttons__icon {
    @include size(36px);
    margin-left: -10px;
    margin-right: 2px;
    transform: translateY(4px);
    transition: transform .3s ease-out;
  }
}
@import "../../styles/variables";
@import "../../styles/mixins";

.div-block-4, .div-block-4 > div {
    z-index: 10;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-column-gap: 1px;
    max-width: 100%;
}

.div-block-4 > div {
    flex-direction: row-reverse;
}

.div-block-4 {
    padding: 16px 2px;
    margin-left: -12px;
    margin-right: -12px;
    overflow: auto;
    max-width: calc(100% + 20px);
    
    @include above($tablet) {
        padding: 24px 8px;
        margin-left: -16px;
        margin-right: -16px;
        width: auto;
        max-width: calc(100% + 16px);
    }
    
    @include above($desktop) {
        padding: 48px 40px;
        margin-left: -64px;
        margin-right: -64px;
        width: auto;
        max-width: calc(100% + 48px);
    }
}

.piano-key-white {
    width: 40px;
    height: 180px;
    background-color: #f7f6f3;
    background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
    border-radius: 1px 1px 3px 3px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: background-color .3s, box-shadow .3s;
    display: flex;
    position: relative;
    box-shadow: inset 1px -1px #fff, inset -1px -1px 1px rgba(0, 0, 0, .2);

    &.pressed {
        height: 199px;
        background-image: linear-gradient(79deg, rgba(11, 11, 11, .17) 18%, rgba(11, 11, 11, 0) 50%), linear-gradient(58deg, rgba(11, 11, 11, .23), rgba(11, 11, 11, 0)), linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
        box-shadow: inset -2px -3px 6px rgba(0, 0, 0, .19), inset 5px -4px 10px -4px rgba(0, 0, 0, .34);
    }

    @include above($tablet) {
        width: 60px;
        height: 200px;
    }

    @include above($desktop) {
        width: 80px;
        height: 240px;
    }
}

.xxx-copy-copy {
    width: 100%;
    height: 20px;
    color: #858585;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 2px;
    font-size: 16px;
    font-family: Roboto Serif;
    font-style: italic;
    display: flex;

    @include above($tablet) {
        padding-bottom: 12px;
        font-size: 20px;
    }

    @include above($desktop) {
        padding-bottom: 16px;
    }
  }
  
  .piano-key-black {
    z-index: 1;
    width: 42px;
    max-width: 64%;
    height: 120px;
    background-color: var(--color-body-dark);
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, .29)), linear-gradient(to bottom, var(--color-body-dark) 88%, rgba(11, 11, 11, 0) 94%), linear-gradient(270deg, rgba(255, 255, 255, .14) 45%, black 50%, rgba(255, 255, 255, .14) 55%);
    border-radius: 0 0 3px 3px;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 3px 13px;
    transition: padding-bottom .3s, box-shadow .3s;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%);
    box-shadow: 2px -1px 3px 1px rgba(0, 0, 0, .47), 7px -2px 7px rgba(0, 0, 0, .23);

    @include below($tablet) {
        width: 20px;
        padding: 0 2px 9px;
    }

    .piano-note {
        min-width: 14px;
        font-size: 16px !important;
    }
  }
  
  .piano-key-black.pressed {
    background-image: linear-gradient(to bottom, var(--color-body-dark) 88%, rgba(11, 11, 11, 0) 94%);
    box-shadow: none;
    padding-bottom: 4px;
  }
  
  .piano-key-black__light {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, .31));
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    transition: opacity .3s, height .3s;
    box-shadow: inset 1px 0 rgba(255, 255, 255, .18), 0 1px 1px rgba(255, 255, 255, .64);
  }
  
  .piano-note {
    z-index: 2;
    height: 10px;
    max-width: calc(100% - 36px);
    white-space: nowrap;
    background-color: rgba(255, 255, 255, .16);
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    font-family: $font-roboto;
    font-size: 18px;
    line-height: 10px;
    display: flex;
    position: absolute;
    bottom: 24px;

    @include above($tablet) {
        font-size: 22px;
        padding: 10px 10px;
        bottom: 32px;
    }

    @include above($tablet) {
        padding: 10px 14px;
        bottom: 48px;
    }
  }
  
  .piano-note.piano-note--e {
    background-color: var(--accent-color-1);
  }
  
  .piano-note.piano-note--f {
    background-color: var(--accent-color-2);
  }
  
  .piano-note.piano-note--g {
    background-color: var(--accent-color-3);
  }
  
  .piano-note.piano-note--a {
    background-color: var(--accent-color-4);
  }
  
  .piano-note.piano-note--b {
    background-color: var(--accent-color-5);
  }
  
  .piano-note.piano-note--c {
    background-color: var(--accent-color-6);
  }
  
  .piano-note.piano-note--d {
    background-color: var(--accent-color-7);
  }
  
  .piano-note.piano-note--diez {
    border-bottom-left-radius: 2px;
    padding: 9px 12px 8px 10px;
    font-size: 20px;
    top: -16px;
    bottom: auto;
    left: 50%;
    transform: translate(0);
  }

  .piano-key-black__light.pressed {
    opacity: .61;
  }

  .piano-note.piano-note--diez.piano-note--c {
    background-image: linear-gradient(to right, var(--accent-color-6), var(--accent-color-7));
  }
  
  .piano-note.piano-note--diez.piano-note--d {
    background-image: linear-gradient(to right, var(--accent-color-7), var(--accent-color-1));
  }
  
  .piano-note.piano-note--diez.piano-note--f {
    background-image: linear-gradient(to right, var(--accent-color-2), var(--accent-color-3));
  }
  
  .piano-note.piano-note--diez.piano-note--g {
    background-image: linear-gradient(to right, var(--accent-color-3), var(--accent-color-4));
  }
  
  .piano-note.piano-note--diez.piano-note--a {
    background-image: linear-gradient(to right, var(--accent-color-4), var(--accent-color-5));
  }
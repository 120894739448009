@import "../../styles/variables";
@import "../../styles/mixins";

.header {
  z-index: 11;
  width: calc(100% - 64px);
  height: 70px;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  backdrop-filter: blur(13px);
  background-color: rgba($color-body-dark, .85);
  grid-template: ". Logo"
  / 1fr 220px 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;
  justify-items: stretch;
  padding: 0 32px;
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
  border-bottom: 1px solid rgba(white, .05);
  display: flex;
  position: fixed;
  @include position(0, 0);

  &.header--down {
    transform: translate(0);
  }

  &.header--up {
    transform: translate(0, -100%);
  }

  .material-icon {
    display: none
  }
}

.navigation__link {
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, .66);
  letter-spacing: 2px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-family: PT Sans, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  position: relative;

  &.w--current {
    color: var(--primary-color);
  }
}

.header__navigation {
  width: 100%;
  justify-content: space-around;
  display: flex;
}

.main-logo__img {
  @include size(130px, 52px);
}
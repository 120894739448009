@import "../../../styles/variables";
@import "../../../styles/mixins";

.div-block-21 {
    display: inline-flex;
    flex-direction: column;
    @include margin-auto;
    width: auto;
    max-width: 800px;
    align-items: center;
    padding: 48px 0;
    text-align: center;
}

.section__headline {
    color: var(--primary-color);
    letter-spacing: -.02em;
    margin-left: -2px;
    font-family: $font-noto-serif;
    font-size: 60px;
    line-height: 60px;
    font-weight: 300;
    display: inline;

    @include above($tablet) {
        font-size: 90px;
        line-height: 90px;
    }

    @include above($desktop) {
        font-size: 120px;
        line-height: 120px;
    }

    & + .subtitle-text {
        margin-top: 48px;
    }
}
@import "../../styles/variables";
@import "../../styles/mixins";

html {
    scroll-behavior: smooth;
}

.tabs__row {
    width: 100%;
    display: flex;

    &.padding-top--1 {
        padding-top: 20px;
    }

    &.padding-top--2 {
        padding-top: 40px;
    }

    &.padding-top--3 {
        padding-top: 60px;
    }

    &.padding-bottom--1 {
        padding-bottom: 20px;
    }

    &.padding-bottom--2 {
        padding-bottom: 40px;
    }

    &.padding-bottom--3 {
        padding-bottom: 60px;
    }
}

.notes-grid__stave {
    padding-left: 60px;
    @include size(100%, 81px);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="20" viewBox="0 0 100 20" fill="none"><rect y="19" width="100" height="1" fill="white" fill-opacity="0.16"/></svg>');
    background-position: 0 -19px;
    background-size: auto;
    display: flex;
    position: relative;

    @include below($tablet) {
        padding-left: 32px;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="15" viewBox="0 0 100 15" fill="none"><rect y="14" width="100" height="1" fill="white" fill-opacity="0.16"/></svg>');
        background-position: 0 -14px;
        height: 61px;
    }

    &.notes-grid__stave--top-bar {
        height: 36px;
        background-image: none;
        background-size: auto;
        grid-template-rows: 36px;
    }

    &._1 {
        display: flex;
    }
}

.notes-grid__key {
    position: absolute;
    left: 0;
    @include size(70px, 160px);
    -webkit-text-fill-color: transparent;
    background-color: $color-note-default;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, .24)), linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
    background-clip: text;
    margin-top: -39px;
    margin-left: -14px;
    font-family: Noto Music, sans-serif;
    font-size: 90px;
    line-height: 150px;

    @include below($tablet) {
        margin-left: -5px;
        margin-top: 0;
        width: 46px;
        height: 62px;
        line-height: 64px;
        font-size: 57px;
    }

    @for $i from 1 through 5 {
        &.notes-grid__key--colored-#{$i} {
            color: var(--accent-color-#{$i});
            background: none;
            -webkit-background-clip: unset;
            background-clip: unset;
            -webkit-text-fill-color: unset;
        }
    }
}

.note__character {
    height: 80px;
    flex: 0 auto;
    justify-content: center;
    display: flex;
    position: relative;
    top: 1px;
    transform: none;

    @include below($tablet) {
        height: 60px;
    }

    @for $i from 1 through 5 {
        &.note__character--color-#{$i} {
            color: var(--accent-color-#{$i});
        }
    }

    svg {
        @include size(50px, 80px);
        transform: translateX(10px);

        @include below($tablet) {
            @include size(36px, 60px)
        }
    }
} 

.note {
    transform-origin: 0%;
    color: $color-note-default-dark;
    flex: 1;
    justify-content: center;
    display: flex;
    position: relative;

    .text-span-12 {
        color: #4a4b50;
    }

    &.note--c {
        padding-top: 10px;
        transform: translate(0, 20px);

        @include below($tablet) {
            padding-top: 7.5px;
            transform: translate(0, 15px);
        }

        &.note--colored {
            color: var(--accent-color-1);

            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-1) 50%, rgba(92, 112, 255, 0));
            }
        }
    }
      
    &.note--d {
        transform: translate(0, 20px);

        @include below($tablet) {
            transform: translate(0, 15px);
        }

        &.note--colored {
            color: var(--accent-color-2)
        }
    }
      
    &.note--e {
        padding-top: 10px;

        @include below($tablet) {
            padding-top: 7.5px;
        }

        &.note--colored {
            color: var(--accent-color-3);

            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-3) 50%, rgba(92, 112, 255, 0));
            }
        }
    }
      
    &.note--f {
        &.note--colored {
            color: var(--accent-color-4);
        }
    }
      
    &.note--g {
        padding-top: 10px;
        transform: translate(0, -20px);

        @include below($tablet) {
            padding-top: 7.5px;
            transform: translate(0, -15px);
        }

        &.note--colored {
            color: var(--accent-color-5);

            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-5) 50%, rgba(92, 112, 255, 0));
            }
        }
    }
      
    &.note--a {
        transform: translate(0, -20px);

        @include below($tablet) {
            transform: translate(0, -15px);
        }

        &.note--colored {
            color: var(--accent-color-6);
        }
    }
      
    &.note--b {
        transform-style: preserve-3d;
        padding-top: 10px;
        transform: translate(100%) rotate(-180deg) translate(0, -40px);

        @include below($tablet) {
            padding-top: 7.5px;
            transform: translate(100%) rotate(-180deg) translate(0, -30px);
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }

        &.note--colored {
            color: var(--accent-color-7);

            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-7) 50%, rgba(92, 112, 255, 0));
            }
        }
    }
      
    &.note--c-2 {
        transform-style: preserve-3d;
        padding-top: 0;
        transform: translate(100%) rotate(-180deg) translate(0, -21px);

        @include below($tablet) {
            transform: translate(100%) rotate(-180deg) translate(0, -16px);
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }
    }
      
    &.note--d-2 {
        padding-top: 10px;
        transform: translate(100%) rotate(-180deg) translate(0, -20px);

        @include below($tablet) {
            padding-top: 7.5px;
            transform: translate(100%) rotate(-180deg) translate(0, -15px);
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }

        &.note--colored {
            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-2) 50%, rgba(92, 112, 255, 0));
            }
        }
    }
      
    &.note--e-2 {
        padding-top: 0;
        transform: translate(100%) rotate(-180deg);

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }
    }
      
    &.note--f-2 {
        transform-style: preserve-3d;
        padding-top: 10px;
        transform: translate(100%) rotate(-180deg);

        @include below($tablet) {
            padding-top: 7.5px;
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }

        &.note--colored {
            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-4) 50%, rgba(92, 112, 255, 0));
            }
        }

    }
      
    &.note--g-2 {
        transform-style: preserve-3d;
        padding-top: 0;
        transform: translate(100%) rotate(-180deg) translate(0, 20px);

        @include below($tablet) {
            transform: translate(100%) rotate(-180deg) translate(0, 15px);
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }
    }
      
    &.note--a-2 {
        transform-style: preserve-3d;
        padding-top: 10px;
        transform: translate(100%) rotate(-180deg) translate(0, 20px);

        @include below($tablet) {
            padding-top: 7.5px;
            transform: translate(100%) rotate(-180deg) translate(0, 15px);
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }

        &.note--colored {
            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-6) 50%, rgba(92, 112, 255, 0));
            }
        }
    }
      
    &.note--b-2 {
        transform-style: preserve-3d;
        padding-top: 0;
        transform: translate(100%) rotate(-180deg) translate(0, 40px);

        @include below($tablet) {
            transform: translate(100%) rotate(-180deg) translate(0, 30px);
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }
    }
      
    &.note--c-3 {
        transform-style: preserve-3d;
        padding-top: 10px;
        transform: translate(100%) rotate(-180deg) translate(0, 40px);

        @include below($tablet) {
            padding-top: 7.5px;
            transform: translate(100%) rotate(-180deg) translate(0, 30px);
        }

        .notes-grid__note-name {
            transform: translate(0, 56px)rotate(180deg);
        }

        &.note--colored {
            &:after {
                background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-1) 50%, rgba(92, 112, 255, 0));
            }
        }
    }

    &.note--c:not(.note--c-2, .note--c-3),
    &.note--d:not(.note--d-2),
    &.note--g-2,
    &.note--a-2,
    &.note--b-2,
    &.note--c-3 {
        &:before {
            content: "";
            @include size(100%, 20px);
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="20" viewBox="0 0 100 20" fill="none"><rect y="19" width="100" height="1" fill="white" fill-opacity="0.16"/></svg>');
            background-position: 50% 100%;
            background-size: auto 20px;
            position: absolute;
            bottom: 0;
            right: 0%;
            overflow: hidden;

            @include below($tablet) {
                height: 15px;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="15" viewBox="0 0 100 15" fill="none"><rect y="14" width="100" height="1" fill="white" fill-opacity="0.16"/></svg>');
                background-size: auto 15px;
            }
        }
    }

    $reverse-notes: "b", "c-2", "d-2", "e-2", "f-2", "g-2", "a-2", "b-2", "c-3";

    @each $note in $reverse-notes {
        &.note--#{$note} {
            &.note--eight {
                .note__flag {
                    transform-origin: 50% 0;
                    transform: scaleX(-1) translate(-1.5px, -7px);
                }
            }
            &.note--sixteenth {
                .note__head-flag-double {
                    transform-origin: 50% 0;
                    transform: scaleX(-1) translate(-0.5px, -4px);
                }
            }
            .note__character {
                svg {
                    transform: translateX(12px) !important;
                }
            }

            .notes-grid__note-name {
                transform: translate(0, 58px) rotate(180deg) !important;
            }
        }
    }

    &.note--b-2,
    &.note--c-3 {
        &:before {
            height: 21px;
        }
    }

    $diez-notes: "c", "e", "g", "b", "d-2", "f-2", "a-2", "c-3";

    @each $note in $diez-notes {
        &.note--#{$note} {
            &:after {
                content: "";
                z-index: -1;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
            }
        }
    }

    &.note--c-2,
    &.note--e-2,
    &.note--g-2,
    &.note--b-2 {
        &:after {
            content: none;
        }
    }

    $notes-lengths: "full", "half", "quart", "eight", "sixteenth";

    @each $note in $notes-lengths {
        $i: 16 / (pow(2, index($notes-lengths, $note) - 1));
        &.note--#{$note} {
            flex: $i;
    
            &-x1 {
                flex: $i * 1.5;
            }
    
            &-x2 {
                flex: $i * 2;
            }
        }
    }

    &.note--colored {
        color: var(--primary-color);

        @for $i from 1 through 9 {
            $index: $i % 7;
            $index: if($index == 0, 7, $index);
            &.note--colored-#{$i} {
              color: var(--accent-color-#{$i});

              &:after {
                  background-image: linear-gradient(280deg, rgba(92, 112, 255, 0), var(--accent-color-#{$i}) 50%, rgba(92, 112, 255, 0));
              }
            }
        }

        .text-span-12 {
            color: white;
        }
    }

    &.note--full {
        .note__character {

            svg {
                transform: translateX(12px);
            }
        }

        .notes-grid__note-name {
            transform: translateY(0);
        }
    }
}

.notes-grid__num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 42px;
    letter-spacing: -0.18em;
    -webkit-text-fill-color: transparent;
    background-color: $color-note-default;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, .23)), linear-gradient(rgba(0, 0, 0, .36), rgba(0, 0, 0, .36));
    -webkit-background-clip: text;
    background-clip: text;
    padding-right: 2px;
    font-family: Minervamodern;
    font-size: 55px;
    font-style: normal;
    line-height: 40px;
    position: absolute;
    @include position(50%, 50%);
    transform: translate(-50%, -50%);

    &.notes-grid__num--16 {
        text-indent: -7px;
    }

    &.top {
        top: 19px;
        transform: translate(-50%, -18px);
    }

    &.bottom {
        top: auto;
        bottom: 23px;
        transform: translate(-50%, 23px);
    }
}

.notes-grid__note-num {
    letter-spacing: -3px;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #fff), linear-gradient(#595959, #595959);
    -webkit-background-clip: text;
    background-clip: text;
    justify-content: center;
    align-items: center;
    font-family: Gravitas One;
    font-size: 32px;
    line-height: 40px;
    display: flex;
} 

.notes-grid__note-name {
    text-align: center;
    -webkit-text-fill-color: inherit;
    cursor: default;
    background-clip: border-box;
    justify-content: center;
    align-items: center;
    font-family: Noto Serif Display, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
    position: absolute;
    transform: translate(12px, -56px);
}

.notes-grid__nums {
    position: relative;
    margin-right: 32px;
    @include size(40px, 100%);
    text-align: center;
    align-items: center;
    display: flex;

    @for $i from 1 through 7 {
        .notes-grid__num--#{$i} {
            background-color: var(--accent-color-#{$i});
            background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.12));
        }
    }
}

.tabs-nums {
    @include position(-9.5px, false, -32px);
    @include size(32px, 96px);
    opacity: .5;
    color: #5e5e5e;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    position: absolute;
}

.notes-grid__num-char {
    text-align: right;
    font-family: $font-roboto;
    line-height: 20px;
}

.notes-group {
    position: relative;
    display: flex;
    flex: 1;

    .notes-group__line {
        position: absolute;
        width: 100%;
        height: 8px;
        left: 20px;
    }

    .note__flag, .note__head-flag-double {
        display: none;
    }

    .note__character {
        width: 100%;
        position: relative;

            svg  {
                overflow: visible;
            }
    }

    .notes-group__flag {
        position: absolute;
        left: 24px;
        @include size(calc(100% + 3px), 80px);

        @include below($tablet) {
            left: 19px;
            width: 100%;
        }
    }
}

.notes-grid__tabs-bar {
    display: flex;
    flex: 1;
    padding: 0 24px;
    border-right: 3px solid #393a3f;

    @include below($tablet) {
        border-right: 1px solid #393a3f;
    }

    .note {
        justify-content: flex-start;

        &:after {
            left: 0;
        }
    }

    .note__character {
        justify-content: flex-start;

        svg {
            transform: translateX(-10px);
        }
    }

    &.notes-grid__tabs-bar--end {
        &:after {
            content: "";
            position: absolute;
            right: -3px;
            top: 0;
            @include size(36px, 100%);
            mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="80" viewBox="0 0 36 80" fill="none"><rect x="15" width="3" height="80" fill="%23D9D9D9"/><rect x="25" width="11" height="80" fill="%23D9D9D9"/></svg>');
            background-color: $color-note-default-dark;

            @include below($tablet) {
                right: -1px;
            }
        }
    }

    &.notes-grid__tabs-bar--repeat-end {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: -3px;
            top: 0;
            @include size(36px, 100%);
            mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 80" preserveAspectRatio="none" fill="none"><rect x="15" width="3" height="80" fill="%23D9D9D9"/><rect x="25" width="11" height="80" fill="%23D9D9D9"/><circle cx="5" cy="29" r="5" fill="%23D9D9D9"/><circle cx="5" cy="50" r="5" fill="%23D9D9D9"/></svg>');
            background-color: $color-note-default-dark;
            -webkit-mask-repeat: no-repeat;

            @include below($tablet) {
                right: -1px;
                -webkit-mask-size: 27px;
                width: 27px;
            }
        }
    }

    &.notes-grid__tabs-bar--repeat-start {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: -3px;
            top: 0;
            @include size(36px, 100%);
            mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 80" preserveAspectRatio="none" fill="none"><rect x="21" y="80" width="3" height="80" transform="rotate(-180 21 80)" fill="%23D9D9D9"/><rect x="11" y="80" width="11" height="80" transform="rotate(-180 11 80)" fill="%23D9D9D9"/><circle cx="31" cy="51" r="5" transform="rotate(-180 31 51)" fill="%23D9D9D9"/><circle cx="31" cy="30" r="5" transform="rotate(-180 31 30)" fill="%23D9D9D9"/></svg>');
            background-color: $color-note-default-dark;
            -webkit-mask-repeat: no-repeat;

            @include below($tablet) {
                right: -1px;
                -webkit-mask-size: 27px;
                width: 27px;
            }
        }
    }

    &.notes-grid__tabs-bar--repeat-end-3 {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: -3px;
            top: 0;
            @include size(36px, 100%);
            mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 80" preserveAspectRatio="none" fill="none"><rect x="15" width="3" height="80" fill="%23D9D9D9"/><rect x="25" width="11" height="80" fill="%23D9D9D9"/><path d="M5 44.5C7.76142 44.5 10 42.2614 10 39.5C10 36.7386 7.76142 34.5 5 34.5C2.23857 34.5 -2.36041e-06 36.7386 -2.36041e-06 39.5C-2.36041e-06 42.2614 2.23857 44.5 5 44.5Z" fill="%23D9D9D9"/><path d="M5 66.5C7.76142 66.5 10 64.2614 10 61.5C10 58.7386 7.76142 56.5 5 56.5C2.23858 56.5 -4.37114e-07 58.7386 -4.37114e-07 61.5C-4.37114e-07 64.2614 2.23858 66.5 5 66.5Z" fill="%23D9D9D9"/><path d="M5 22.5C7.76142 22.5 10 20.2614 10 17.5C10 14.7386 7.76142 12.5 5 12.5C2.23857 12.5 -4.28372e-06 14.7386 -4.28372e-06 17.5C-4.28372e-06 20.2614 2.23857 22.5 5 22.5Z" fill="%23D9D9D9"/></svg>');
            background-color: $color-note-default-dark;
            -webkit-mask-repeat: no-repeat;

            @include below($tablet) {
                right: -1px;
                -webkit-mask-size: 27px;
                width: 27px;
            }
        }
    }

    &.notes-grid__tabs-bar--repeat-start-3 {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: -3px;
            top: 0;
            @include size(36px, 100%);
            mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 80" preserveAspectRatio="none" fill="none"><rect x="21" y="80" width="3" height="80" transform="rotate(-180 21 80)" fill="%23D9D9D9"/><rect x="11" y="80" width="11" height="80" transform="rotate(-180 11 80)" fill="%23D9D9D9"/><path d="M31 35.5C28.2386 35.5 26 37.7386 26 40.5C26 43.2614 28.2386 45.5 31 45.5C33.7614 45.5 36 43.2614 36 40.5C36 37.7386 33.7614 35.5 31 35.5Z" fill="%23D9D9D9"/><path d="M31 13.5C28.2386 13.5 26 15.7386 26 18.5C26 21.2614 28.2386 23.5 31 23.5C33.7614 23.5 36 21.2614 36 18.5C36 15.7386 33.7614 13.5 31 13.5Z" fill="%23D9D9D9"/><path d="M31 57.5C28.2386 57.5 26 59.7386 26 62.5C26 65.2614 28.2386 67.5 31 67.5C33.7614 67.5 36 65.2614 36 62.5C36 59.7386 33.7614 57.5 31 57.5Z" fill="%23D9D9D9"/></svg>');
            background-color: $color-note-default-dark;
            -webkit-mask-repeat: no-repeat;

            @include below($tablet) {
                right: -1px;
                -webkit-mask-size: 27px;
                width: 27px;
            }
        }
    }

    @for $i from 1 through 5 {
        &.notes-grid__tabs-bar--colored-#{$i} {
            border-right-color: var(--accent-color-#{$i});

            &:before,
            &:after {
                background-color: var(--accent-color-#{$i});
            }
        }
    }

    $reverse-notes: "b", "c-2", "d-2", "e-2", "f-2", "g-2", "a-2", "b-2", "c-3";

    @each $note in $reverse-notes {
        .note--#{$note} {
            justify-content: flex-end;
    
            svg {
                transform: translateX(10px);
            }
        }
    }

    &[comment] {
        position: relative;

        &:after {
            content: attr(comment);
            position: absolute;
            height: 60px;
            background: none;
            @include position(-68px, 4px);
            opacity: .2;
            font-family: $font-garamond;
            font-style: italic;
            white-space: nowrap;
            font-size: 21px;
            text-align: center;
            border-bottom: 0;
            border-radius: 32px 32px 0 0;
            color: white;
            background-image: 
                url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="54" viewBox="0 0 36 54" fill="none"><path d="M1 54V54C1 36.3269 15.3269 22 33 22H36" stroke="white" stroke-width="2" stroke-linecap="round"/></svg>'), 
                url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 36 54" fill="none"><path d="M0 22L36 22" stroke="white" stroke-width="2"/></svg>'),  
                url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 36 54" fill="none"><path d="M0 22L36 22" stroke="white" stroke-width="2"/></svg>'), 
                url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="74" height="54" viewBox="0 0 74 54" fill="none"><path d="M0 22H17.2946C26.0576 22 33.8184 16.3427 36.5 8V8V8C39.1816 16.3427 46.9424 22 55.7054 22H74" stroke="white" stroke-width="2" stroke-linejoin="round"/></svg>'), 
                url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="54" viewBox="0 0 36 54" fill="none"><path d="M35 54V54C35 36.3269 20.6731 22 3 22H0" stroke="white" stroke-width="2" stroke-linecap="round"/></svg>');
            background-position: 0 20px, 36px 20px, calc(100% - 36px) 20px, 50% 20px, 100% 20px;
            background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
            background-size: 36px 54px, calc(50% - 72px) 54px, calc(50% - 72px) 54px, 74px 54px, 36px 54px;

            @include below($tablet) {
                top: -52px;
                height: 48px;
                background-size: 27px 40px, calc(50% - 54px) 40px, calc(50% - 54px) 40px, 55px 40px, 27px 40px;
                background-position: 0 20px, 27px 20px, calc(100% - 27px) 20px, 50% 20px, 100% 20px;
                font-size: 18px;
            }
        }
    }

    @for $i from 1 through 6 {
        &.comment--#{$i} {
            &:after {
                width: calc((100% * $i) - 8px);
            }
        }
    }
}

.tabs-bar {
    display: flex;
    flex: 1;
}
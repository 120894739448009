@import "../../styles/variables";
@import "../../styles/mixins";

.chords-grid {
  width: 100%;
  display: grid;
  flex-flow: wrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  margin-top: -5px;
  margin-bottom: -5px;

  @include below($desktop) {
    grid-template-columns: 1fr;
  }

  @include above($desktop-xxl) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.collection-list-wrapper {
  padding-left: 5px;
  padding-right: 5px;

  @media screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.chords-grid-wrapper {
  position: relative;
  padding: 24px 0;
}